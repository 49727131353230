import React from "react";
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ContactForm from '../../components/contact-form';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact" keywords={['DJ', 'Samuel', 'Andres', 'Pittsburgh', 'House']} />
        <h1>Contact</h1>
        <ContactForm />
      </Layout>
    );
  }
}